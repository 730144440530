import React, { useState } from "react";
import withStyles from "@mui/styles/withStyles";
import { Typography, TextField, FormControlLabel, FormHelperText, Checkbox, Button, Select, MenuItem, FormControl, InputLabel, LinearProgress, Tooltip } from "@mui/material";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import StopIcon from "@mui/icons-material/Close";
import DeleteIcon from "@mui/icons-material/Delete";
import CancelIcon from "@mui/icons-material/Cancel";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";

import * as ValidationUtils from "../../../utils/validationUtils";

const styles = (theme) => ({
    warningProgress: {
        backgroundColor: theme.palette.warning.main
    }
});

function RasterUpload({ onChange, onCancel, onCancelUpload, onUpload, uploadStatus, classes }) {
    function onNameChanged(e) {
        let value = e.target.value;
        let upload = uploadStatus;

        upload.name = value;
        upload.nameError = value === "";
        onChange(upload);
        //setNameError(value !== "");
    }

    function onCancelClicked(e) {
        onCancel();
    }

    function onUploadClicked(e) {
        if (validateInfo()) {
            onUpload(uploadStatus);
        }
    }

    function validateInfo() {
        let upload = uploadStatus;

        upload.nameError = upload.name === "";

        onChange(upload);

        return !upload.nameError;
    }

    function renderProgressBar(upload, classes) {
        switch (upload.status) {
            case "Failed":
                return (
                    <div className="progress error">
                        <LinearProgress className="progress-bar" variant="determinate" value={upload.progress} />
                        <div className="status">Failed</div>
                    </div>
                );
            case "Cancelled":
                return (
                    <div className="progress warning">
                        <LinearProgress className="progress-bar" variant="determinate" value={upload.progress} />
                        <div className="status">Cancelled</div>
                    </div>
                );
            default:
                return (
                    <div className="progress">
                        <LinearProgress className="progress-bar" variant="determinate" value={upload.progress} />
                        {upload.progress < 100 && upload.progress > 0 && <div className="status">{upload.progress}%</div>}
                        {(upload.progress === 100 || upload.progress === 0) && <div className="status">{upload.status}</div>}
                    </div>
                );
        }
    }

    return (
        <div className="upload">
            {!uploadStatus.uploading && (
                <div className="upload-container">
                    <Tooltip title="Raster">
                        <div className="type">R</div>
                    </Tooltip>

                    <div className="section">
                        <Tooltip title="Can only contain [A-Z] [0-9] [.,()] or space">
                            <TextField
                                id="full-width"
                                inputProps={{ autoComplete: "no" }}
                                error={uploadStatus.nameError}
                                placeholder="Name of the raster"
                                value={uploadStatus.name}
                                onChange={onNameChanged}
                                fullWidth
                                margin="none"
                                variant="outlined"
                                label="Raster Name"
                            />
                        </Tooltip>
                    </div>
                    <div className="flex-grow"></div>
                    <Tooltip title="Upload">
                        <div className="file-upload-button" onClick={onUploadClicked}>
                            <CloudUploadIcon />
                        </div>
                    </Tooltip>
                    <Tooltip title="Remove">
                        <div className="file-upload-button" onClick={onCancelClicked}>
                            <DeleteIcon />
                        </div>
                    </Tooltip>
                </div>
            )}
            {uploadStatus.uploading && (
                <div className="uploading-container">
                    <div className="uploading-info">
                        <Tooltip title="Raster">
                            <div className={"type " + uploadStatus.status}>R</div>
                        </Tooltip>
                        <Tooltip title={uploadStatus.name}>
                            <div className={"file-name " + uploadStatus.status}>{uploadStatus.name}</div>
                        </Tooltip>
                        {renderProgressBar(uploadStatus, classes)}
                        {uploadStatus.message && (
                            <Tooltip title={uploadStatus.message}>
                                <ErrorOutlineIcon className="error-icon" />
                            </Tooltip>
                        )}
                        {console.log(uploadStatus.status.toLowerCase())}
                        <Tooltip title={uploadStatus.status !== "Uploading" ? "Remove" : "Cancel"}>
                            <div className={"file-upload-button " + uploadStatus.status.toLowerCase()} onClick={() => onCancelUpload(uploadStatus)}>
                                {uploadStatus.status !== "Uploading" ? <DeleteIcon /> : <CancelIcon />}
                            </div>
                        </Tooltip>
                    </div>
                </div>
            )}
        </div>
    );
}

export default withStyles(styles)(RasterUpload);
