import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Redirect, Route, Router, Switch } from "react-router-dom";
import { history } from "./store";
import { withAuthenticationRequired, useAuth0 } from "@auth0/auth0-react";
import apiClient from "./actions/apiClient";
import ReduxToastr from "react-redux-toastr";

import "./scss/main.scss";

import LoaderComponent from "./components/auth0Loader/LoaderComponent";

import DatasetsView from "./views/UploadRootView";
import NavigationMenu from "./components/navigationMenu/navigationMenu";

function App() {
    const { getAccessTokenSilently } = useAuth0();

    const [tokenRetrieved, setTokenRetrieved] = useState(false);

    useEffect(() => {
        // Update the document title using the browser API
        getAccessTokenSilently().then((token) => {
            apiClient.defaults.headers.common["Authorization"] = "Bearer " + token;
            setTokenRetrieved(true);
        });
    });

    if (!tokenRetrieved) return null;

    return (
        <div className="App">
            <Router history={history}>
                <NavigationMenu />
                <Switch>
                    <Route path="/upload" component={DatasetsView} />
                    <Redirect to="/upload" />
                </Switch>
            </Router>
            <ReduxToastr
                timeOut={4000}
                newestOnTop={true}
                position="top-right"
                getState={(state) => state.toastr} // This is the default
                transitionIn="fadeIn"
                transitionOut="fadeOut"
                closeOnToastrClick
            />
        </div>
    );
}

const mapStateToProps = () => ({});

const mapDispatchToProps = {};

export default withAuthenticationRequired(connect(mapStateToProps, mapDispatchToProps)(App), {
    // Show a message while the user waits to be redirected to the login page.
    onRedirecting: () => <LoaderComponent />
});
