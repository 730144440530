import React, { useState } from "react";

import InfoIcon from "@mui/icons-material/Info";
import DeleteIcon from "@mui/icons-material/Delete";
import MenuIcon from "@mui/icons-material/MoreVert";
import Tooltip from "@mui/material/Tooltip";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import moment from "moment";

export default function HistoryItem({ history, selected, onClick, onDelete, onDetails }) {
    const [anchorEl, setAnchorEl] = useState(null);

    const onMenuClick = (event) => {
        event.stopPropagation();
        setAnchorEl(event.currentTarget);
    };

    const onMenuClose = (e) => {
        e.stopPropagation();
        setAnchorEl(null);
    };

    const onDeleteClick = (e) => {
        onMenuClose(e);
        e.stopPropagation();
        onDelete(history);
    };

    const onDetailsClick = (e) => {
        onMenuClose(e);
        e.stopPropagation();
        onDetails(history);
    };

    return (
        <div className={selected ? "item selected" : "item"} onClick={() => onClick(history)}>
            <div className="container">
                <div className="row-container">
                    <div className={"row-1"}>
                        <div className="name">{history.fileName}</div>

                        <Tooltip id="tooltip-top" title={moment(history.timeStamp).local().format("MMMM Do YYYY, h:mm:ss a")}>
                            <div className="date">{moment(history.timeStamp).local().format("MMMM Do YYYY h:mm:ss a")}</div>
                        </Tooltip>
                        <div className="row-count">{history.featuresEndId - history.featuresStartId + 1}</div>

                        <Tooltip id="tooltip-top" title="Options">
                            <MenuIcon className="button" onClick={onMenuClick} />
                        </Tooltip>
                    </div>
                </div>

                <Menu anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={onMenuClose}>
                    <MenuItem onClick={onDetailsClick} className="menu-button">
                        <InfoIcon fontSize="small" sx={{ marginRight: 1 }} />
                        Details
                    </MenuItem>

                    <MenuItem onClick={onDeleteClick} className="menu-button">
                        <DeleteIcon className="icon" />
                        Delete
                    </MenuItem>
                </Menu>
            </div>
        </div>
    );
}
