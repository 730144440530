import * as types from "../actions/actionTypes";

const datasetHistoryInit = {
    fetching: false,
    datasetHistory: [],
    deletingHistoryLoading: false,
    deletingHistoryError: null
};

export function datasetHistoryReducer(state = datasetHistoryInit, action) {
    switch (action.type) {
        case types.FETCH_DATASET_HISTORY:
            return {
                ...state,
                fetching: true
            };
        case types.FETCH_DATASET_HISTORY_COMPLETE:
            return {
                ...state,
                fetching: false,
                datasetHistory: action.result
            };
        case types.APPEND_DATASET_COMPLETE:
            return {
                ...state,
                fetching: false,
                datasetHistory: [action.result, ...state.datasetHistory]
            };
        case types.DELETE_DATASET_HISTORY_ENTRY:
            return {
                ...state,
                deletingHistoryLoading: true,
                deletingHistoryError: null
            };
        case types.DELETE_DATASET_HISTORY_ENTRY_COMPLETE:
            return {
                ...state,
                datasetHistory: state.datasetHistory.filter((history) => history.id !== action.payload.id),
                deletingHistoryLoading: false
            };
        case types.DELETE_DATASET_HISTORY_ENTRY_FAILED:
            return {
                ...state,
                deletingHistoryLoading: false,
                deletingHistoryError: action.payload
            };
        default:
            return state;
    }
}
