import React from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import Tooltip from "@mui/material/Tooltip";

import UploadIcon from "@mui/icons-material/CloudUpload";

import { history } from "../../store";
import { Box } from "@mui/system";

let navigationLocations = [
    {
        name: "Upload",
        route: "/upload",
        icon: <UploadIcon className="icon" />
    }
];

function NavigationMenu(props) {
    function onClick(route) {
        history.push(route);
    }

    return (
        <div className="navigation-menu">
            <div className="container">
                <Box height={48} />
                {navigationLocations.map((item, index) => {
                    return (
                        <Tooltip title={item.name} placement="right" key={index}>
                            <div className={props.location.pathname.startsWith(item.route) ? "button active" : "button"} onClick={() => onClick(item.route)}>
                                {item.icon}
                            </div>
                        </Tooltip>
                    );
                })}
            </div>
        </div>
    );
}

const mapStateToProps = (state, ownProps) => {
    return {};
};

const mapDispatchToProps = (dispatch, ownProps) => {
    return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(NavigationMenu));
