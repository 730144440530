import React, { Component } from "react";
import { connect } from "react-redux";
import { Redirect, Route, Switch } from "react-router-dom";

import ScopesView from "./scopesView";
import ScopeDetails from "./scopeDetailsView";
import * as datasetActions from "../actions/datasets";

class UploadRootView extends Component {
    constructor(props) {
        super(props);

        this.state = {
            selected: null,
            columns: []
        };
    }

    componentDidMount() {
        this.props.getDatasetsWithWritePermission().then((res) => {
            this.setState({
                selected: res.result.filter((x) => x.id === this.props.match.params.datasetId)[0]
            });
        });
        if (this.state.selected) {
            this.props.getDatasetColumns(this.state.selected).then((res) => {
                this.setState({
                    columns: res.result
                });
            });
        }
    }

    onScopeClick = (dataset) => {
        this.setState({
            selected: dataset
        });
    };

    render() {
        let { match } = this.props;

        return (
            <div className="datasets-root">
                <Switch>
                    <Route exact path={`${match.path}/`} component={ScopesView} />
                    <Route path={`${match.path}/:datasetId`} component={ScopeDetails} />
                    <Redirect to={`${match.path}/`} />
                </Switch>
            </div>
        );
    }
}

const mapStateToProps = (state, ownProps) => ({});

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        getDatasetsWithWritePermission: () => dispatch(datasetActions.getDatasetsWithWritePermission()),
        getDatasetColumns: (datasetId) => dispatch(datasetActions.getDatasetColumns(datasetId))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(UploadRootView);
