import { combineReducers } from "redux";
// import { reducer as toastrReducer } from "react-redux-toastr";
import { datasetsReducer } from "./datasets";
// import { mapReducer } from "./map";
// import { authReducer } from "./auth";
// import { listViewReducer } from "./listView";
// import { mapServiceReducer, mapDetailsReducer } from "./mapService";
// import { usersReducer } from "./users";
// import { groupsReducer } from "./groups";
// import { appsReducer, appDetailsReducer } from "./apps";
// import { tableViewReducer } from "./tableView";
// import { notificationReducer } from "./notifications";
// import { rastersReducer, rasterDetailsReducer } from "./rasters";
// import { metadataSchemaReducer } from "./metadataSchema";
import { uploaderReducer } from "./uploader";
// import { schemasReducer } from "./schemas";
import { datasetHistoryReducer } from "./datasetHistory";
import { reducer as toastrReducer } from "react-redux-toastr";

// main reducers
const reducers = combineReducers({
  datasets: datasetsReducer,
  uploader: uploaderReducer,
  datasetHistory: datasetHistoryReducer,
  toastr: toastrReducer,
});

export default reducers;
