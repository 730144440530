import { createTheme, adaptV4Theme, lighten, darken } from "@mui/material/styles";

import styles from "./scss/variables.module.scss";

const PRIMARY_COLOR = styles.primaryColor;
const SECONDARY_COLOR = styles.secondaryColor;
const APP_BG = styles.appBgColor;
const APP_BG_LIGHT = styles.appBgColorLight;
const APP_BG_DARK = styles.appBgColorDark;
const SUCCESS_COLOR = styles.successColor;
const WARNING_COLOR = styles.warningColor;
const SEMANTIC_2 = styles.semantic2;
const ERROR_COLOR = styles.errorColor;
const APP_TEXT_COLOR = styles.appTextColor;
const APP_TEXT_COLOR_LIGHT = styles.appTextColorLight;
const TEXT_COLOR_WHITE = styles.whiteText;
const WHITE = styles.white;
const LIGHT_GRAY = styles.lightGray;
const GRAY = styles.gray;
const DARK_GRAY = styles.darkGray;
const BLACK = styles.black;
const BORDER_COLOR = styles.borderColor;

let theme = {
    customColors: {
        primaryColor: PRIMARY_COLOR,
        secondaryColor: SECONDARY_COLOR,
        appBgColor: APP_BG,
        appBgColorLight: APP_BG_LIGHT,
        appBgColorDark: APP_BG_DARK,
        appTextColor: APP_TEXT_COLOR,
        appTextColorLight: APP_TEXT_COLOR_LIGHT,
        whiteText: TEXT_COLOR_WHITE,
        successColor: SUCCESS_COLOR,
        warningColor: WARNING_COLOR,
        errorColor: ERROR_COLOR,
        semantic2: SEMANTIC_2,
        white: WHITE,
        lightGray: LIGHT_GRAY,
        gray: GRAY,
        darkGrey: DARK_GRAY,
        black: BLACK,
        borderColor: BORDER_COLOR
    },
    typography: {
        useNextVariants: true,
        fontFamily: ['"Nunito Sans"', "-apple-system", "BlinkMacSystemFont", '"Helvetica Neue"', "Arial", "sans-serif"].join(","),
        h1: {
            fontSize: 24,
            lineHeight: "36px"
        },
        h2: {
            fontSize: 20,
            lineHeight: "30px"
        },
        h3: {
            fontSize: 18,
            lineHeight: "26px"
        },
        subtitle1: {
            fontSize: 16,
            lineHeight: "24px"
        },
        body1: {
            fontSize: 16,
            lineHeight: "22px"
        },
        body2: {
            fontSize: 14,
            lineHeight: "20px"
        },
        caption: {
            fontSize: 12,
            lineHeight: "18px"
        }
    },
    palette: {
        canvasColor: "white",
        mode: "light",
        background: APP_BG,
        secondaryBackground: {
            main: APP_BG_LIGHT
        },
        primary: {
            main: PRIMARY_COLOR,
            light: lighten(PRIMARY_COLOR, 0.2),
            dark: darken(PRIMARY_COLOR, 0.2)
        },
        secondary: {
            main: PRIMARY_COLOR,
            light: lighten(PRIMARY_COLOR, 0.2),
            dark: darken(PRIMARY_COLOR, 0.2)
        },
        success: {
            main: SUCCESS_COLOR,
            light: lighten(SUCCESS_COLOR, 0.2),
            dark: darken(SUCCESS_COLOR, 0.2)
        },
        text: {
            main: APP_TEXT_COLOR
        },
        warning: {
            main: WARNING_COLOR
        },
        error: {
            main: ERROR_COLOR
        }
    },
    overrides: {
        MuiTypography: {
            root: {
                color: APP_TEXT_COLOR
            }
        },
        MuiSelect: {
            root: {
                width: "100%"
            },
            select: {
                color: APP_TEXT_COLOR,
                width: "100%"
            },
            icon: {
                color: APP_TEXT_COLOR
            }
        },
        MuiButtonBase: {
            root: {
                textTransform: "capitalize"
            }
        },
        MuiButton: {
            root: {
                textTransform: "capitalize"
            }
        },
        MuiTableCell: {
            body: {
                color: APP_TEXT_COLOR
            }
        },
        MuiTableSortLabel: {
            root: {
                color: APP_TEXT_COLOR
            }
        },
        MuiTablePagination: {
            root: {
                color: APP_TEXT_COLOR
            },
            actions: {
                color: APP_TEXT_COLOR
            }
        },
        MuiFormControlLabel: {
            label: {
                color: APP_TEXT_COLOR
            }
        },
        MuiCheckbox: {
            root: {
                color: "gray",
                "&$checked": {
                    color: APP_TEXT_COLOR + "!important"
                }
            }
        },
        MuiPaper: {
            root: {
                backgroundColor: "white"
            }
        },
        MuiInput: {
            root: {
                color: APP_TEXT_COLOR
            },
            underline: {
                "&:before": {
                    "border-bottom": "gray 1px solid"
                },
                "&:hover:before": {
                    "border-bottom": "lightgray 1px solid !important"
                },
                "&:after": {
                    "border-bottom": PRIMARY_COLOR + " 2px solid"
                }
            }
        },
        MuiFilledInput: {
            root: {
                backgroundColor: "transparent"
            }
        },
        MuiFormLabel: {
            root: {
                color: APP_TEXT_COLOR,
                "&$focused": {
                    // increase the specificity for the pseudo class
                    color: PRIMARY_COLOR
                }
            }
        },
        MuiFormControl: {
            root: {
                color: "gray"
            }
        },
        MuiMenu: {
            paper: {
                backgroundColor: "white"
            }
        },
        MuiMenuItem: {
            root: {
                color: APP_TEXT_COLOR
            }
        },
        MuiLinearProgress: {
            colorPrimary: {
                backgroundColor: "transparent"
            }
        },
        MuiAppBar: {
            root: {
                boxShadow: "none",
                height: 48,
                minHeight: 48
            }
        },
        MuiToolbar: {
            root: {
                paddingLeft: "16px!important",
                height: 48,
                minHeight: "48px!important"
            }
        },
        MuiTooltip: {
            tooltip: {
                fontSize: 12,
                maxWidth: 700
            }
        },
        MuiTab: {
            wrapper: {
                color: APP_TEXT_COLOR
            },
            root: {
                backgroundColor: APP_BG_LIGHT
            }
        }
    },
    props: {
        MuiButton: {
            disableElevation: true
        },
        MuiTooltip: {
            PopperProps: {
                disablePortal: true
            },
            arrow: true
        },
        MuiTextField: {
            inputProps: {
                style: { fontWeight: "bold" }
            }
        }
    }
};

export default createTheme(adaptV4Theme(theme));
