import React, { Component } from "react";
import { connect } from "react-redux";
import { history } from "../store";

import withStyles from "@mui/styles/withStyles";

import { AppBar, Divider, IconButton, LinearProgress, TextField, Toolbar, Tooltip, Typography } from "@mui/material";
import * as datasetActions from "../actions/datasets";

import Uploader from "../components/uploader/uploader";
import DatasetHistory from "../components/datasetHistory/datasetHistory";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import ReadOnlyField from "../components/common/ReadOnlyField/ReadOnlyField";
import { Box } from "@mui/system";
import HeaderButtons from "../components/common/HeaderButtons/HeaderButtons";

const styles = (theme) => ({
    colorBlack: {
        color: "black"
    }
});

class ScopesView extends Component {
    constructor(props) {
        super(props);

        this.state = {
            dataset: {
                name: "",
                geometryType: "",
                rowCount: ""
            },
            columns: [],
            fetching: true
        };
    }

    componentDidMount() {
        this.props
            .getDatasetColumns(this.props.match.params.datasetId)
            .then((res) => {
                this.setState({
                    columns: res.result
                });
            })
            .catch(() => history.push("/upload"));

        this.props.getDatasetDetails(this.props.match.params.datasetId).then((res) => {
            this.setState({
                dataset: res.result,
                fetching: false
            });
        });
    }

    onBackClick = () => {
        history.push("/upload");
    };

    renderColumnType(type) {
        switch (type) {
            case "integer":
            case "bigint":
                return (
                    <div className="column-type">
                        Number
                        <div className="icon">123</div>
                    </div>
                );
            case "double precision":
                return (
                    <div className="column-type">
                        Decimal
                        <div className="icon">1.23</div>
                    </div>
                );
            case "character varying":
                return (
                    <div className="column-type">
                        Text
                        <div className="icon">Abc</div>
                    </div>
                );
            case "timestamp":
            case "timestamp with time zone":
                return (
                    <div className="column-type">
                        Timestamp
                        <div className="icon">Date</div>
                    </div>
                );
            case "date":
                return (
                    <div className="column-type">
                        Date
                        <div className="icon">Date</div>
                    </div>
                );
        }
    }

    renderColumns(columns) {
        return columns.map((column) => {
            return (
                <div key={column.name} className="column-info-container">
                    <Tooltip id="tooltip-top" title={column.name}>
                        <div className="column-name">{column.name}</div>
                    </Tooltip>

                    <div className="grow"></div>

                    {this.renderColumnType(column.type)}
                </div>
            );
        });
    }

    removeFromRowCount = (nrOfRemovedRows) => {
        this.setState((state) => ({
            ...state,
            dataset: {
                ...state.dataset,
                rowCount: state.dataset.rowCount - nrOfRemovedRows
            }
        }));
    };

    render() {
        let { match } = this.props;
        let { dataset } = this.state;

        return (
            <div className="scope-details-root">
                <div className="sidebar">
                    <AppBar position="static">
                        <Toolbar variant="dense" className="toolbar">
                            <img className="logo" src="/fidu-logo.png" alt="Lautec WindGIS"></img>
                            <div className="seperater"></div>
                        </Toolbar>
                    </AppBar>

                    <div className="header">
                        <IconButton style={{ marginRight: 8, marginLeft: -8 }} onClick={this.onBackClick}>
                            <KeyboardBackspaceIcon fontSize="small" className={this.props.classes.colorBlack} />
                        </IconButton>
                        <Typography variant="h6" className="grow">
                            Scope Details
                        </Typography>
                    </div>
                    {this.state.fetching && <LinearProgress variant="indeterminate" />}
                    <div className="scope-info-container">
                        <div className="field">
                            <ReadOnlyField label="Name" text={dataset.name} textVariant="h6" />
                        </div>
                        <div className="field flex">
                            <ReadOnlyField label="Geometry Type" text={dataset.geometryType} />
                            <ReadOnlyField label="Row Count" text={dataset.rowCount} />
                        </div>
                        <Box marginY={2}>
                            <Divider />
                        </Box>
                        <Typography variant="h6">Expected Columns</Typography>
                        <div className="columns-section">
                            <div className="column-info-header">
                                <div className="column-name">Column Name</div>
                                <div className="column-type">Column Type</div>
                            </div>
                            <div className="columns-container">{this.renderColumns(this.state.columns.filter((x) => x.name !== "ogc_fid"))}</div>
                        </div>
                    </div>
                </div>
                <div className="top-bar">
                    <HeaderButtons />
                </div>
                <Uploader datasetId={this.props.match.params.datasetId} geometryType={dataset.geometryType} />
                <DatasetHistory datasetId={this.props.match.params.datasetId} removeFromRowCount={this.removeFromRowCount} />
            </div>
        );
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        datasets: state.datasets.datasets
    };
};

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        getDatasetDetails: (datasetId) => dispatch(datasetActions.getDatasetDetails(datasetId)),
        getDatasetColumns: (datasetId) => dispatch(datasetActions.getDatasetColumns(datasetId))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(ScopesView));
