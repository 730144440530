import * as types from "../actions/actionTypes";

const datasetsInit = {
    fetching: false,
    datasets: [],
    selected: {}
};

export function datasetsReducer(state = datasetsInit, action) {
    switch (action.type) {
        case types.FETCH_DATASETS:
            return {
                ...state,
                fetching: true
            };
        case types.FETCH_DATASETS_COMPLETE:
            return {
                ...state,
                fetching: false,
                datasets: [...action.result]
            };
        case types.SELECT_DATASET:
            return {
                ...state,
                selected: action.result
            };

        case types.CREATE_DATASET_COMPLETE:
            return {
                ...state,
                datasets: [...state.datasets, action.result]
            };
        case types.DELETE_DATASET:
            return {
                ...state,
                fetching: true
            };
        case types.DELETE_DATASET_COMPLETE:
            return {
                ...state,
                fetching: false,
                datasets: state.datasets.filter((x) => x.id !== action.result)
            };
        case types.DATASET_ACTION_FAILED:
            return {
                ...state,
                fetching: false
            };
        case types.SIGNALR_PROGRESS_UPDATE:
            return {
                ...state,
                datasets: updateArrayCacheStatus(state.datasets, action.result)
            };
        case types.SIGNALR_ERROR_UPDATE:
            return {
                ...state,
                datasets: updateArrayWithCacheStatus(state.datasets, action.result, 0)
            };
        case types.DELETE_DATASET_HISTORY_ENTRY_COMPLETE:
            const nrOfDeletedFeatures = action.payload.featuresEndId - action.payload.featuresStartId + 1;
            return {
                ...state,
                datasets: state.datasets.map((dataset) => {
                    if (dataset.id !== action.payload.datasetId) {
                        return dataset;
                    }
                    return { ...dataset, rowCount: dataset.rowCount - nrOfDeletedFeatures };
                })
            };
        default:
            return state;
    }
}

function updateCacheStatus(cacheStatus, datasetId, notification) {
    if (datasetId === notification.id) {
        return notification.cacheStatus;
    }
    return cacheStatus;
}

function updateArrayCacheStatus(array, notification) {
    let newArray = array.map((item) => {
        if (item.id === notification.id) {
            return {
                ...item,
                cacheStatus: notification.cacheStatus
            };
        }
        return item;
    });

    return newArray;
}

function updateArrayWithCacheStatus(array, notification, cacheStatus) {
    let newArray = array.map((item) => {
        if (item.id === notification.id) {
            return {
                ...item,
                cacheStatus: cacheStatus
            };
        }
        return item;
    });

    return newArray;
}
