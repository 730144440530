import * as types from "./actionTypes";
import axiosClient from "./apiClient";
import fileDownload from "js-file-download";

export function getDatasetsWithWritePermission() {
    return (dispatch) => {
        dispatch({ type: types.FETCH_DATASETS });

        return axiosClient.get(`dataset?minPermission=3`).then(
            (res) => {
                return dispatch({
                    type: types.FETCH_DATASETS_COMPLETE,
                    result: res.data
                });
            },
            (err) => {
                dispatch({ type: types.DATASET_ACTION_FAILED, result: err });
                throw err;
            }
        );
    };
}

export function getDatasetHistoryEntryFeatures(datasetId, historyId) {
    return () => {
        return axiosClient.get(`dataset/${datasetId}/history/${historyId}/features`).then((res) => res.data);
    };
}

export function createDataset(formData, config) {
    return (dispatch) => {
        dispatch({ type: types.CREATE_DATASET });

        return axiosClient
            .post("dataset", formData, {
                ...config,
                headers: {
                    "Content-Type": "multipart/form-data"
                }
            })
            .then(
                (res) => {
                    return dispatch({
                        type: types.CREATE_DATASET_COMPLETE,
                        result: res.data
                    });
                },
                (err) => {
                    dispatch({ type: types.DATASET_ACTION_FAILED, result: err });
                    throw err;
                }
            );
    };
}

export function createDatasetCsv(formData, config) {
    return (dispatch) => {
        dispatch({ type: types.CREATE_DATASET });

        return axiosClient
            .post("dataset/csv", formData, {
                ...config,
                headers: {
                    "Content-Type": "multipart/form-data"
                }
            })
            .then(
                (res) => {
                    return dispatch({
                        type: types.CREATE_DATASET_COMPLETE,
                        result: res.data
                    });
                },
                (err) => {
                    dispatch({ type: types.DATASET_ACTION_FAILED, result: err });
                    throw err;
                }
            );
    };
}

export function createDatasetKml(formData, config) {
    return (dispatch) => {
        dispatch({ type: types.CREATE_DATASET });

        return axiosClient
            .post("dataset/kml", formData, {
                ...config,
                headers: {
                    "Content-Type": "multipart/form-data"
                }
            })
            .then(
                (res) => {
                    return dispatch({
                        type: types.CREATE_DATASET_COMPLETE,
                        result: res.data
                    });
                },
                (err) => {
                    dispatch({ type: types.DATASET_ACTION_FAILED, result: err });
                    throw err;
                }
            );
    };
}

export function appendDatasetZip(datasetId, formData, config) {
    return (dispatch) => {
        dispatch({ type: types.APPEND_DATASET });

        return axiosClient
            .post(`dataset/${datasetId}/append`, formData, {
                ...config,
                headers: {
                    "Content-Type": "multipart/form-data"
                }
            })
            .then(
                (res) => {
                    dispatch({
                        type: types.APPEND_DATASET_COMPLETE,
                        result: res.data.generatedHistory
                    });
                    return res.data;
                },
                (err) => {
                    dispatch({ type: types.DATASET_ACTION_FAILED, result: err });
                    throw err;
                }
            );
    };
}

export function appendDatasetGeojson(datasetId, formData, config) {
    return (dispatch) => {
        dispatch({ type: types.APPEND_DATASET });

        return axiosClient
            .post(`dataset/${datasetId}/append/geojson`, formData, {
                ...config,
                headers: {
                    "Content-Type": "multipart/form-data"
                }
            })
            .then(
                (res) => {
                    dispatch({
                        type: types.APPEND_DATASET_COMPLETE,
                        result: res.data.generatedHistory
                    });
                    return res.data;
                },
                (err) => {
                    dispatch({ type: types.DATASET_ACTION_FAILED, result: err });
                    throw err;
                }
            );
    };
}

export function createDatasetGeoPackage(formData, config) {
    return (dispatch) => {
        dispatch({ type: types.CREATE_DATASET });

        return axiosClient
            .post("dataset/gpkg", formData, {
                ...config,
                headers: {
                    "Content-Type": "multipart/form-data"
                }
            })
            .then(
                (res) => {
                    return dispatch({
                        type: types.CREATE_DATASET_COMPLETE,
                        result: res.data
                    });
                },
                (err) => {
                    dispatch({ type: types.DATASET_ACTION_FAILED, result: err });
                    throw err;
                }
            );
    };
}

export function createDatasetFromMemory(data, datasetRequest) {
    return (dispatch) => {
        dispatch({ type: types.CREATE_DATASET });

        let blob = new Blob([JSON.stringify(data)]);

        let formData = new FormData();

        formData.append("file", blob, "data.geojson");
        formData.append("name", datasetRequest.name);
        formData.append("tableName", datasetRequest.tableName);
        formData.append("schemaName", datasetRequest.schemaName);
        formData.append("generateCache", datasetRequest.generateCache);

        for (var i = 0; i < datasetRequest.groups.length; i++) {
            formData.append("Groups[" + i + "]", datasetRequest.groups[i]);
        }

        return axiosClient
            .post("dataset", formData, {
                headers: {
                    "Content-Type": "multipart/form-data"
                }
            })
            .then(
                (res) => {
                    return dispatch({
                        type: types.CREATE_DATASET_COMPLETE,
                        result: res.data
                    });
                },
                (err) => {
                    dispatch({ type: types.DATASET_ACTION_FAILED, result: err });
                    throw err;
                }
            );
    };
}

export function replaceDataset(datasetId, formData, config) {
    return (dispatch) => {
        dispatch({ type: types.UPDATE_DATASET });

        return axiosClient
            .post(`dataset/${datasetId}/replace`, formData, {
                ...config,
                headers: {
                    "Content-Type": "multipart/form-data"
                }
            })
            .then(
                (res) => {
                    return dispatch({
                        type: types.UPDATE_DATASET_COMPLETE,
                        result: res.data
                    });
                },
                (err) => {
                    dispatch({ type: types.DATASET_ACTION_FAILED, result: err });
                    throw err;
                }
            );
    };
}

export function downloadDatasetTemplate(datasetId, filename, format, config) {
    return (dispatch) => {
        dispatch({ type: types.DOWNLOAD_DATASET });

        return axiosClient
            .get(`dataset/${datasetId}/download/${format}/template`, {
                ...config,
                responseType: "blob"
            })
            .then(
                (res) => {
                    dispatch({ type: types.DOWNLOAD_DATASET_COMPLETE });
                    fileDownload(res.data, filename);
                },
                (err) => {
                    dispatch({ type: types.DATASET_ACTION_FAILED, result: err });
                    throw err;
                }
            );
    };
}

export function updateDataset(id, updateRequest) {
    return (dispatch) => {
        dispatch({ type: types.UPDATE_DATASET });

        return axiosClient.put("dataset/meta/" + id, updateRequest).then(
            (res) => {
                dispatch({ type: types.UPDATE_DATASET_COMPLETE, result: res.data });
            },
            (err) => {
                dispatch({ type: types.DATASET_ACTION_FAILED, result: err });
                throw err;
            }
        );
    };
}

export function updateDatasetAdvanced(id, updateRequest) {
    return (dispatch) => {
        dispatch({ type: types.UPDATE_DATASET_ADVANCED });

        return axiosClient.put("dataset/meta/" + id + "/advanced", updateRequest).then(
            (res) => {
                dispatch({
                    type: types.UPDATE_DATASET_ADVANCED_COMPLETE,
                    result: res.data
                });
            },
            (err) => {
                dispatch({ type: types.DATASET_ACTION_FAILED, result: err });
                throw err;
            }
        );
    };
}

export function deleteDataset(id) {
    return (dispatch) => {
        dispatch({ type: types.DELETE_DATASET });

        return axiosClient.delete("dataset/meta/" + id).then(
            (res) => {
                dispatch({ type: types.DELETE_DATASET_COMPLETE, result: id });
            },
            (err) => {
                dispatch({ type: types.DATASET_ACTION_FAILED, result: err });
                throw err;
            }
        );
    };
}

export function getDatasetDetails(id) {
    return (dispatch) => {
        dispatch({ type: types.FETCH_DATASET_DETAILS });

        return axiosClient.get("dataset/meta/" + id).then(
            (res) => {
                return dispatch({
                    type: types.FETCH_DATASET_DETAILS_COMPLETE,
                    result: res.data
                });
            },
            (err) => {
                dispatch({ type: types.DATASET_ACTION_FAILED, result: err });
                throw err;
            }
        );
    };
}

export function getDatasetHistory(id) {
    return (dispatch) => {
        dispatch({ type: types.FETCH_DATASET_HISTORY });

        return axiosClient.get("dataset/" + id + "/history").then(
            (res) => {
                return dispatch({
                    type: types.FETCH_DATASET_HISTORY_COMPLETE,
                    result: res.data
                });
            },
            (err) => {
                dispatch({ type: types.DATASET_ACTION_FAILED, result: err });
                throw err;
            }
        );
    };
}

export function getDatasetColumns(datasetId) {
    return (dispatch) => {
        return axiosClient.get(`dataset/${datasetId}/columns`).then(
            (res) => {
                return { result: res.data, id: datasetId };
            },
            (err) => {
                dispatch({ type: types.DATASET_ACTION_FAILED, result: err });
                throw err;
            }
        );
    };
}

export function generateCache(datasetId) {
    return (dispatch) => {
        dispatch({ type: types.GENERATE_DATASET_CACHE });

        return axiosClient.post(`dataset/${datasetId}/generate`).then(
            (res) => {
                dispatch({
                    type: types.GENERATE_DATASET_CACHE_COMPLETE,
                    result: res.data
                });
            },
            (err) => {
                dispatch({ type: types.DATASET_ACTION_FAILED, result: err });
                throw err;
            }
        );
    };
}

export function cancelGenerateCache(datasetId) {
    return (dispatch) => {
        dispatch({ type: types.CANCEL_GENERATE_DATASET_CACHE });

        return axiosClient.post(`dataset/${datasetId}/generate/cancel`).then(
            (res) => {
                dispatch({
                    type: types.CANCEL_GENERATE_DATASET_CACHE_COMPLETE,
                    result: datasetId
                });
            },
            (err) => {
                dispatch({ type: types.DATASET_ACTION_FAILED, result: err });
                throw err;
            }
        );
    };
}

export function getSchemaNames() {
    return (dispatch) => {
        dispatch({ type: types.FETCH_SCHEMA_NAMES });

        return axiosClient.get("dataset/schema").then(
            (res) => {
                return dispatch({
                    type: types.FETCH_SCHEMA_NAMES_COMPLETE,
                    result: res.data
                });
            },
            (err) => {
                dispatch({ type: types.DATASET_ACTION_FAILED, result: err });
                throw err;
            }
        );
    };
}

export function deleteDatasetHistoryEntry(history) {
    return (dispatch) => {
        dispatch({ type: types.DELETE_DATASET_HISTORY_ENTRY });

        return axiosClient
            .delete(`dataset/${history.datasetId}/history/${history.id}`)
            .then(() => {
                return dispatch({
                    type: types.DELETE_DATASET_HISTORY_ENTRY_COMPLETE,
                    payload: history
                });
            })
            .catch((err) => {
                dispatch({ type: types.DELETE_DATASET_HISTORY_ENTRY_FAILED, payload: err });
                throw err;
            });
    };
}

export function getAsGeoJson(datasetId) {
    return (dispatch) => {
        dispatch({ type: types.FETCH_DATASET_GEOJSON });

        return axiosClient.get(`dataset/${datasetId}/geojson`).then(
            (res) => {
                return dispatch({
                    type: types.FETCH_DATASET_GEOJSON_COMPLETE,
                    result: res.data.features
                });
            },
            (err) => {
                dispatch({ type: types.DATASET_ACTION_FAILED, result: err });
                throw err;
            }
        );
    };
}
