import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";

import Config from "./config";

import { Provider } from "react-redux";
import { store } from "./store.js";
import { Auth0Provider } from "@auth0/auth0-react";
import { ThemeProvider, StyledEngineProvider } from "@mui/material";
import theme from "./muiTheme";
import Auth0Wrapper from "./Auth0Wrapper";

ReactDOM.render(
    <Auth0Provider
        domain={Config.auth0Domain}
        clientId={Config.auth0ClientId}
        redirectUri={window.location.origin}
        audience={Config.auth0Audience}
        scope="read:current_user update:current_user_metadata"
    >
        <StyledEngineProvider injectFirst>
            <ThemeProvider theme={theme}>
                <Provider store={store}>
                    <Auth0Wrapper>
                        <App />
                    </Auth0Wrapper>
                </Provider>
            </ThemeProvider>
        </StyledEngineProvider>
    </Auth0Provider>,
    document.getElementById("root")
);
