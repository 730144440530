import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import reducers from './reducers/index';

import { createBrowserHistory } from 'history'

let middlewares = [];

middlewares.push(thunk);



let middleware = applyMiddleware(...middlewares);

// add the redux dev tools
if (process.env.NODE_ENV !== 'production' && window.__REDUX_DEVTOOLS_EXTENSION__) {
    middleware = compose(middleware, window.__REDUX_DEVTOOLS_EXTENSION__());
}

let history = createBrowserHistory({
    /* pass a configuration object here if needed */
})

const store = createStore(
    reducers,
    middleware
);  

export {store,history}