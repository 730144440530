import { CircularProgress, Dialog, DialogContent, DialogTitle, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { getDatasetHistoryEntryFeatures } from "../../actions/datasets";
import FeaturesTable from "../FeaturesTable/FeaturesTable";
import { useHistoryDetailsModalStyles } from "./styles";
import moment from "moment";

const HistoryDetailsModal = ({ history, onClose }) => {
    const classes = useHistoryDetailsModalStyles();
    const [features, setFeatures] = useState([]);
    const [rowCount, setRowCount] = useState(0);

    const [loading, setLoading] = useState(false);
    const [err, setErr] = useState(null);
    const dispatch = useDispatch();

    useEffect(() => {
        setErr(null);
        setLoading(true);
        if (history)
            dispatch(getDatasetHistoryEntryFeatures(history.datasetId, history.id))
                .then((res) => {
                    setFeatures(res.features);
                    setRowCount(res.totalRowCount);
                    setLoading(false);
                })
                .catch((err) => {
                    setErr(err);
                    setLoading(false);
                });
    }, [history.id]);

    const Loading = () => {
        return (
            <div className={classes.centerContent}>
                <CircularProgress />
            </div>
        );
    };

    const Error = () => {
        return (
            <div className={classes.centerContent}>
                <Typography>Encountered an error while retrieveing the features</Typography>
            </div>
        );
    };

    const TableOfFeatures = () => {
        return (
            <div className={classes.tableWrapper}>
                <FeaturesTable features={features} rowCount={rowCount} />
            </div>
        );
    };

    return (
        <Dialog open onClose={onClose} maxWidth="lg" fullWidth>
            <DialogTitle>{history.fileName}</DialogTitle>
            <DialogContent dividers className={classes.dialogHeight}>
                <Typography className={classes.sectionTitle}>Features uploaded on {moment(history.timeStamp).local().format("MMMM Do YYYY")}</Typography>
                {loading ? <Loading /> : err ? <Error /> : <TableOfFeatures />}
            </DialogContent>
        </Dialog>
    );
};

export default HistoryDetailsModal;
