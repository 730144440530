import axios from "axios";
import config from "../config";

export default axios.create({
    baseURL: config.apiUrl + "api/",
    timeout: 300000,
    crossDomain: true,
    "Content-Type": "application/json",
    withCredentials: true
});
