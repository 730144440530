import React, { useState } from "react";

import TextField from "@mui/material/TextField";
import { TablePagination, Tooltip } from "@mui/material";

export default function ListView({ searchPlaceholder, data, columns, filterFunction, generateItem }) {
    const sortings = columns.reduce((a, b) => {
        a[b.name] = b.sortingFunction;
        return a;
    }, {});

    const [searchString, setsearchString] = useState("");
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(20);

    const [sorting, setSorting] = useState(columns.filter((x) => x.start)[0].name);
    const [sortingReverse, setSortingReverse] = useState(false);

    function onSetSort(column) {
        setSortingReverse(sorting === column.name ? !sortingReverse : false);
        setSorting(column.name);
    }

    function onSearchChanged(e) {
        if (searchString === "") {
            setPage(0);
        }

        setsearchString(e.target.value);
    }

    const filteredData = data.filter((x) => filterFunction(x, searchString));

    let sliceStart = page * rowsPerPage;
    let SliceEnd = page * rowsPerPage + rowsPerPage;

    let items = filteredData
        .sort(sortings[sorting](sortingReverse))
        .slice(sliceStart, SliceEnd)
        .map((item, index) => generateItem(item, index));

    return (
        <div className="list-view">
            {/* <div className="search">
                <TextField
                    id="full-width"
                    inputProps={{ autoComplete: "off" }}
                    placeholder={searchPlaceholder}
                    value={searchString}
                    onChange={onSearchChanged}
                    fullWidth
                    margin="dense"
                />
            </div> */}
            <div className="scroll-container">
                <div className="sort-toolbar">
                    {columns.map((column, columnIndex) => {
                        return (
                            <Tooltip key={columnIndex} id="tooltip-top" title={`Sort by ${column.name}`}>
                                <div className={(sorting === column.name ? "button active " : "button ") + column.type} onClick={() => onSetSort(column)}>
                                    {column.icon ? column.icon : column.name}
                                </div>
                            </Tooltip>
                        );
                    })}
                    <div className="filler"></div>
                </div>
                <div className="item-container">{items.length === 0 ? <div className="no-data-indicator">Upload to scope to add history</div> : items}</div>
            </div>
            <div className="pagination">
                <TablePagination
                    rowsPerPageOptions={[10, 20, 30]}
                    component="div"
                    count={filteredData.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    backIconButtonProps={{
                        "aria-label": "Previous Page"
                    }}
                    nextIconButtonProps={{
                        "aria-label": "Next Page"
                    }}
                    onPageChange={(e, page) => setPage(page)}
                    onRowsPerPageChange={(e) => setRowsPerPage(e.target.value)}
                />
            </div>
        </div>
    );
}
