import React from "react";

import Tooltip from "@mui/material/Tooltip";

import { Typography } from "@mui/material";

import { GeometryIcons } from "../utils/customIcons";
import SvgIcon from "@mui/material/SvgIcon";
import moment from "moment";

function ScopeItem({ dataset, selected, onClick }) {

  function renderGeometryType(geometryType) {
    switch (geometryType) {
      case "POLYGON":
      case "MULTIPOLYGON":
        return <SvgIcon>{GeometryIcons.polygon}</SvgIcon>;
      case "LINESTRING":
      case "MULTILINESTRING":
        return <SvgIcon>{GeometryIcons.line}</SvgIcon>;
      case "POINT":
      case "MULTIPOINT":
        return <SvgIcon>{GeometryIcons.point}</SvgIcon>;
      default:
    }
  }

  return (
    <div
      className={selected ? "scope selected" : "scope"}
      onClick={() => onClick(dataset)}
    >
      <div className="scope-header">
        {renderGeometryType(dataset.geometryType)}
        <Tooltip title={dataset.name}>
          <Typography variant="subtitle2" className="grow">
            {dataset.name}
          </Typography>
        </Tooltip>

        <div className="grow"></div>
        <div className="date">
          {moment(dataset.modifiedUtc).local().fromNow()}
        </div>
      </div>
    </div>
  );
}

export default ScopeItem;
