import React, { useState, useEffect } from "react";
import { Tooltip } from "@mui/material";
import Typography from "../CustomTypography/CustomTypography";
import { useStyles } from "./style.js";

const OverflowTip = (props) => {
    const { variant, fontWeight, color } = props;

    const [isOverflowed, setIsOverflowed] = useState(false);
    const textElementRef = React.createRef();
    const classes = useStyles();

    useEffect(() => {
        const observer = new ResizeObserver((entries) => {
            const textElement = entries[0];
            //This is mainly used to check that the values are different than 0
            //When isOverflowed changes, the Typography we attach the ref to changes too, thus
            //the ref.current returns values of 0 (it gets unmounted). We can ignore this because we know we'll
            //receive new values from the newly mounted typography.
            if (textElement.target.scrollWidth && textElement.target.clientWidth) setIsOverflowed(textElement.target.scrollWidth > textElement.target.clientWidth);
        });
        observer.observe(textElementRef.current);
        return () => textElementRef.current && observer.unobserve(textElementRef.current);
    }, [isOverflowed]);

    return (
        <div className={classes.textDiv}>
            {isOverflowed ? (
                <Tooltip title={props.children} arrow>
                    <div>
                        <Typography ref={textElementRef} variant={variant} fontWeight={fontWeight} color={color} noWrap>
                            {props.children}
                        </Typography>
                    </div>
                </Tooltip>
            ) : (
                <Typography ref={textElementRef} variant={variant} fontWeight={fontWeight} color={color}>
                    {props.children}
                </Typography>
            )}
        </div>
    );
};

export default OverflowTip;
