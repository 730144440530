import { toastr as reduxToastr } from "react-redux-toastr";

const options = { position: "bottom-right" };

const toastr = {
    info: (text, opt = options) => reduxToastr.info("", text, opt),
    success: (text, opt = options) => reduxToastr.success("", text, opt),
    warning: (text, opt = options) => reduxToastr.warning("", text, opt),
    error: (text, opt = options) => reduxToastr.error("", text, opt),
    confirm: (text, opt) => reduxToastr.confirm(text, opt)
};

export default toastr;
