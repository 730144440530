import React, { Component } from "react";
import { connect } from "react-redux";
import * as datasetsActions from "../../actions/datasets";
import withStyles from "@mui/styles/withStyles";
import HistoryItem from "./historyItem";
import ListView from "../listview";
import { Divider, Typography } from "@mui/material";
import HistoryDetailsModal from "../HistoryDetailsModal/HistoryDetailsModal";
import toastr from "../../utils/customToastr";

const styles = (theme) => ({});

class DatasetHistory extends Component {
    state = {
        detailsModalOpen: false,
        selectedHistory: null
    };

    constructor(props) {
        super(props);

        this.columns = [
            {
                type: "name",
                name: "File name",
                sortingFunction: (sortingReverse) => (sortingReverse ? this.sortByNameReverse : this.sortByName)
            },
            {
                type: "date",
                name: "Uploaded",
                start: true,
                sortingFunction: (sortingReverse) => (sortingReverse ? this.sortByTimestampReverse : this.sortByTimestamp)
            },
            {
                type: "text",
                name: "Rows",
                sortingFunction: (sortingReverse) => (sortingReverse ? this.sortByTimestampReverse : this.sortByTimestamp)
            }
        ];
    }

    componentDidMount() {
        this.props.getHistory(this.props.datasetId);
    }

    componentDidUpdate(prevProps) {
        if (this.props.datasetId != prevProps.datasetId) {
            this.props.getHistory(this.props.datasetId);
        }
    }

    sortByTimestamp(history1, history2) {
        return new Date(history2.timeStamp) - new Date(history1.timeStamp);
    }

    sortByTimestampReverse(history1, history2) {
        return new Date(history1.timeStamp) - new Date(history2.timeStamp);
    }

    setSelectedHistory = (history) => {
        this.setState({ selectedHistory: history, detailsModalOpen: true });
    };

    onCloseDetailsModal = () => {
        this.setState({ selectedHistory: null });
    };

    onDeleteHistoryEntry = (history) => {
        const toastrConfirmOptions = {
            onOk: () =>
                this.props
                    .deleteHistoryEntry(history)
                    .then(() => {
                        const numberOfDeletedFeatures = history.featuresEndId - history.featuresStartId + 1;
                        toastr.success("History entry deleted succesfully", { position: "bottom-right" });
                        this.props.removeFromRowCount(numberOfDeletedFeatures);
                    })
                    .catch(() => toastr.error("Encounterd an error when deleting the history entry.", { position: "bottom-right" })),
            onCancel: () => console.log("CANCEL: clicked")
        };
        toastr.confirm("Delete history entry", toastrConfirmOptions);
    };

    onHistoryEntryClick = () => {};

    generateItem = (item, index) => {
        return <HistoryItem history={item} key={index} onDelete={this.onDeleteHistoryEntry} onClick={this.onHistoryEntryClick} onDetails={this.setSelectedHistory} />;
    };

    filterItem(item, query) {
        return true;
    }

    render() {
        let { match } = this.props;

        return (
            <div className="dataset-history">
                <div className="sub-header">
                    <Typography variant="h6">History</Typography>
                </div>
                <ListView searchPlaceholder={"Search rasters"} data={this.props.history} columns={this.columns} generateItem={this.generateItem} filterFunction={this.filterItem} />
                {this.state.selectedHistory && <HistoryDetailsModal history={this.state.selectedHistory} onClose={this.onCloseDetailsModal} />}
            </div>
        );
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        history: state.datasetHistory.datasetHistory
    };
};

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        getHistory: (datasetId) => dispatch(datasetsActions.getDatasetHistory(datasetId)),
        deleteHistoryEntry: (history) => dispatch(datasetsActions.deleteDatasetHistoryEntry(history))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(DatasetHistory));
