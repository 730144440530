import * as types from "./actionTypes";
import axiosClient from "./apiClient";

export function getRasters() {
    return (dispatch) => {
        dispatch({ type: types.FETCH_RASTERS });

        return axiosClient.get("raster").then(
            (res) => {
                return dispatch({ type: types.FETCH_RASTERS_COMPLETE, result: res.data });
            },
            (err) => {
                dispatch({ type: types.RASTER_ACTION_FAILED, result: err });
                throw err;
            }
        );
    };
}

export function getRaster(rasterId) {
    return (dispatch) => {
        dispatch({ type: types.FETCH_RASTER });

        return axiosClient.get("raster/" + rasterId).then(
            (res) => {
                return dispatch({ type: types.FETCH_RASTER_COMPLETE, result: res.data });
            },
            (err) => {
                dispatch({ type: types.RASTER_ACTION_FAILED, result: err });
                throw err;
            }
        );
    };
}

export function updateRaster(rasterId, request) {
    return (dispatch) => {
        dispatch({ type: types.UPDATE_RASTER });

        return axiosClient.put("raster/" + rasterId, request).then(
            (res) => {
                return dispatch({ type: types.UPDATE_RASTER_COMPLETE, result: res.data });
            },
            (err) => {
                dispatch({ type: types.RASTER_ACTION_FAILED, result: err });
                throw err;
            }
        );
    };
}

export function deleteRaster(rasterId) {
    return (dispatch) => {
        dispatch({ type: types.DELETE_RASTER });

        return axiosClient.delete("raster/" + rasterId).then(
            (res) => {
                return dispatch({ type: types.DELETE_RASTER_COMPLETE, result: rasterId });
            },
            (err) => {
                dispatch({ type: types.RASTER_ACTION_FAILED, result: err });
                throw err;
            }
        );
    };
}

export function createRaster(formData, config) {
    return (dispatch) => {
        dispatch({ type: types.CREATE_RASTER });

        return axiosClient
            .post("raster", formData, {
                ...config,
                headers: {
                    "Content-Type": "multipart/form-data"
                }
            })
            .then(
                (res) => {
                    return dispatch({ type: types.CREATE_RASTER_COMPLETE, result: res.data });
                },
                (err) => {
                    dispatch({ type: types.RASTER_ACTION_FAILED, result: err });
                    throw err;
                }
            );
    };
}
