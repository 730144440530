import React from "react";
import { Grid, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { ReactComponent as LoadingAnimation } from "./loading-animation.svg";

const useStyles = makeStyles(() => ({
    root: {
        height: "100vh",
        width: "100vw",
        backgroundColor: "#f5f7f8"
    },
    loaderContainer: {
        borderRadius: 5
    },
    container: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column"
    }
}));

const LoaderComponent = () => {
    const classes = useStyles();
    return (
        <Grid container className={classes.root} justifyContent="center" alignItems="center">
            <Grid item xs={12} className={classes.loaderContainer}>
                <Grid container justifyContent="center" alignItems="center" direction="column" className={classes.container}>
                    <Typography variant="h6">WindGIS Authenticating...</Typography>
                    <LoadingAnimation height="125" />
                </Grid>
            </Grid>
        </Grid>
    );
};

export default LoaderComponent;
