import React, { Component } from "react";
import { connect } from "react-redux";
import withStyles from "@mui/styles/withStyles";

import * as datasetsActions from "../../actions/datasets";
import * as rasterServiceActions from "../../actions/rasterService";

import { toastr } from "react-redux-toastr";
import axios from "axios";
import Typography from "../common/CustomTypography/CustomTypography";
import { Button, Menu, MenuItem } from "@mui/material";

import { v4 as uuidv4 } from "uuid";
import AddIcon from "@mui/icons-material/AddCircle";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import Upload from "./components/upload";
import RasterUpload from "./components/rasterUpload";
import * as ValidationUtils from "../../utils/validationUtils";
import CsvDialog from "./csvDialog";
import HeaderButtons from "../common/HeaderButtons/HeaderButtons";

const styles = (theme) => ({
    warningProgress: {
        backgroundColor: theme.palette.warning.main
    },
    errorProgress: {
        backgroundColor: theme.palette.error.main
    }
});

class Uploader extends Component {
    state = {
        dragging: false,
        additionalInfo: {},
        uploads: [],
        csvUploads: [],
        schemaNames: [],
        templateMenuAnchor: null,
        accept: this.geometryTypeToAccepted(this.props.geometryType)
    };

    uploadQueue = [];
    uploadQueuer = null;
    uploading = false;
    dragCounter = 0;
    componentDidMount() {
        this.uploadQueuer = setInterval(() => {
            if (!this.uploading && this.uploadQueue.length > 0) {
                this.uploading = true;
                let uploadJob = this.uploadQueue.shift();

                let uploadPromise = this.buildUpload(uploadJob, uploadJob.cancelTokenSource);

                uploadPromise.then(
                    (res) => {
                        this.uploading = false;
                        if (this.props.onUploadCompleted) {
                            this.props.onUploadCompleted(res.result);
                        }

                        this.setState({
                            uploads: this.state.uploads.map((item) => {
                                if (item.id === uploadJob.id) {
                                    return {
                                        ...item,
                                        status: "Completed"
                                    };
                                }
                                return item;
                            })
                        });
                    },
                    (err) => {
                        this.uploading = false;

                        if (axios.isCancel(err)) {
                            this.onUploadFailed(uploadJob.id, "Cancelled", null);
                        } else {
                            this.onUploadFailed(uploadJob.id, "Failed", err.response.data.error);
                        }
                    }
                );
            }
        }, 500);
    }

    componentDidUpdate(prevProps) {
        if (this.props.datasetId != prevProps.datasetId) {
            this.setState({
                uploads: []
            });
        }
        if (this.props.geometryType != prevProps.geometryType) {
            this.setState({
                accept: this.geometryTypeToAccepted(this.props.geometryType)
            });
        }
    }

    geometryTypeToAccepted(geometryType) {
        switch (geometryType) {
            case "POLYGON":
            case "MULTIPOLYGON":
                return ".zip";
            case "LINESTRING":
            case "MULTILINESTRING":
                return ".zip";
            case "POINT":
            case "MULTIPOINT":
                return ".zip,.csv,.tsv";
            default:
        }
    }

    onUploadFailed(id, status, message) {
        this.setState({
            uploads: this.state.uploads.map((item) => {
                if (item.id === id) {
                    return {
                        ...item,
                        status: status,
                        progress: 100,
                        message: message,
                        cancelTokenSource: null
                    };
                }
                return item;
            })
        });
    }

    buildUpload(uploadJob, cancelTokenSource) {
        switch (uploadJob.type) {
            case "geojson":
                return this.buildGeojsonUpload(uploadJob, cancelTokenSource);
            case "csv":
                return this.buildCsvUpload(uploadJob, cancelTokenSource);
            case "zip":
                return this.buildShapeZipUpload(uploadJob, cancelTokenSource);
            case "gpkg":
                return this.buildGeoPackageUpload(uploadJob, cancelTokenSource);
            case "kml":
                return this.buildKmlUpload(uploadJob, cancelTokenSource);
            case "mbtiles":
                return this.buildMbtilesUpload(uploadJob, cancelTokenSource);
            case "memoryGeojson":
                return this.buildMemoryGeojsonUpload(uploadJob, cancelTokenSource);
            default:
                console.log("invalid file type");
        }
    }

    buildGeojsonUpload(uploadJob, cancelTokenSource) {
        let formData = new FormData();
        formData.append("file", uploadJob.file);
        formData.append("name", uploadJob.name);
        formData.append("tableName", uploadJob.tableName);
        formData.append("schemaName", uploadJob.schemaName);
        formData.append("generateCache", uploadJob.generateCache);

        let config = {
            cancelToken: cancelTokenSource.token,
            onUploadProgress: (progress) => this.onUploadProgress(progress, uploadJob.id)
        };

        return this.props.createDataset(formData, config);
    }

    buildKmlUpload(uploadJob, cancelTokenSource) {
        let formData = new FormData();
        formData.append("file", uploadJob.file);
        formData.append("name", uploadJob.name);
        formData.append("tableName", uploadJob.tableName);
        formData.append("schemaName", uploadJob.schemaName);
        formData.append("generateCache", uploadJob.generateCache);

        let config = {
            cancelToken: cancelTokenSource.token,
            onUploadProgress: (progress) => this.onUploadProgress(progress, uploadJob.id)
        };

        return this.props.createDatasetKml(formData, config);
    }

    buildShapeZipUpload(uploadJob, cancelTokenSource) {
        let formData = new FormData();
        formData.append("file", uploadJob.file);
        formData.append("generateCache", true);

        let config = {
            cancelToken: cancelTokenSource.token,
            onUploadProgress: (progress) => this.onUploadProgress(progress, uploadJob.id)
        };

        return this.props.appendDatasetZip(uploadJob.datasetId, formData, config).then((res) => {
            if (!res.succesfullyEmailedFiduContacts) {
                toastr.warning("", "Dataset was extended succesfully, but an error occured when sending the email notification.");
            }
            return res.generatedHistory;
        });
    }

    buildGeoPackageUpload(uploadJob, cancelTokenSource) {
        let formData = new FormData();
        formData.append("file", uploadJob.file);
        formData.append("name", uploadJob.name);
        formData.append("tableName", uploadJob.tableName);
        formData.append("schemaName", uploadJob.schemaName);
        formData.append("generateCache", uploadJob.generateCache);

        let config = {
            cancelToken: cancelTokenSource.token,
            onUploadProgress: (progress) => this.onUploadProgress(progress, uploadJob.id)
        };

        return this.props.createDatasetGeoPackage(formData, config);
    }

    buildCsvUpload(uploadJob, cancelTokenSource) {
        let formData = new FormData();
        formData.append("file", uploadJob.file);
        formData.append("name", uploadJob.name);
        formData.append("tableName", uploadJob.tableName);
        formData.append("schemaName", uploadJob.schemaName);
        formData.append("generateCache", uploadJob.generateCache);
        formData.append("longitudeName", this.state.additionalInfo.lonfield);
        formData.append("latitudeName", this.state.additionalInfo.latfield);
        formData.append("projection", this.state.additionalInfo.projection);

        let config = {
            cancelToken: cancelTokenSource.token,
            onUploadProgress: (progress) => this.onUploadProgress(progress, uploadJob.id)
        };

        return this.props.createDatasetCsv(formData, config);
    }

    buildMbtilesUpload(uploadJob, cancelTokenSource) {
        let formData = new FormData();

        formData.append("file", uploadJob.file);
        formData.append("name", uploadJob.name);

        let config = {
            cancelToken: cancelTokenSource.token,
            onUploadProgress: (progress) => this.onUploadProgress(progress, uploadJob.id)
        };

        return this.props.createRasterMbtiles(formData, config);
    }

    buildMemoryGeojsonUpload(uploadJob, cancelTokenSource) {
        let formData = new FormData();
        let blob = new Blob([JSON.stringify(uploadJob.file)]);
        formData.append("file", blob, uploadJob.name + ".geojson");
        formData.append("name", uploadJob.name);
        formData.append("tableName", uploadJob.tableName);
        formData.append("schemaName", uploadJob.schemaName);
        formData.append("generateCache", uploadJob.generateCache);
        let config = {
            cancelToken: cancelTokenSource.token,
            onUploadProgress: (progress) => this.onUploadProgress(progress, uploadJob.id)
        };

        return this.props.appendDatasetGeojson(uploadJob.datasetId, formData, config).then((res) => {
            if (!res.succesfullyEmailedFiduContacts) {
                toastr.warning("", "Dataset was extended succesfully, but an error occured when sending the email notification.");
            }
            return res.generatedHistory;
        });
    }

    onOpenTemplateMenu = (event) => {
        this.setState({
            templateMenuAnchor: event.currentTarget
        });
    };

    onCloseTemplateMenu = () => {
        this.setState({
            templateMenuAnchor: null
        });
    };

    componentWillUnmount() {
        clearInterval(this.uploadQueuer);
    }

    onFileDragOver = (e) => {
        e.preventDefault();
        e.stopPropagation();
        this.dragCounter++;

        this.setState({
            dragging: true
        });
    };

    onFileDragLeave = (e) => {
        e.preventDefault();
        e.stopPropagation();
        this.dragCounter--;

        this.setState({
            dragging: false
        });
    };

    onFileDrop = (e) => {
        e.preventDefault();

        let fileExtension = "." + e.dataTransfer.files[0].name.split(".").pop().toLowerCase();

        if (!this.state.accept.includes(fileExtension)) {
            this.setState({
                dragging: false
            });
            return;
        }

        this.setState({
            dragging: false
        });

        this.handleFiles(e.dataTransfer.files);
    };

    onFileChanged = (e) => {
        const files = e.target.files;

        this.handleFiles(files);

        document.getElementById("file-button").value = "";
    };

    // handleFiles = (files) => {
    //   let newUploads = [];
    //   for (let i = 0; i < files.length; i++) {
    //     let file = files[i];
    //     let fileNameWithoutExtension = file.name.split(".")[0];
    //     let fileExtension = file.name.split(".").pop().toLowerCase();
    //     let tableName = fileNameWithoutExtension.toLowerCase().replace(" ", "_");
    //     newUploads.push({
    //       name: fileNameWithoutExtension,
    //       schemaName: "data",
    //       tableName: tableName,
    //       nameError: false,
    //       schemaNameError: false,
    //       tableNameError: false,
    //       generateCache: true,
    //       uploading: false,
    //       id: uuidv4(),
    //       file: file,
    //       progress: 0,
    //       status: "Queued",
    //       type: fileExtension,
    //       cancelTokenSource: null,
    //       datasetId: this.props.datasetId,
    //     });
    //   }

    //   this.setState({
    //     uploads: [...this.state.uploads, ...newUploads],
    //   });
    // };

    handleFiles = (files) => {
        let newUploads = [];
        let newCsvUploads = [];

        for (let i = 0; i < files.length; i++) {
            let file = files[i];
            let fileExtension = file.name.split(".").pop().toLowerCase();
            switch (fileExtension) {
                case "zip":
                    this.addUpload(newUploads, file);
                    break;
                case "csv":
                case "tsv":
                    newCsvUploads.push(file);
                    break;
            }
        }

        this.setState({
            uploads: [...this.state.uploads, ...newUploads],
            csvUploads: [...this.state.csvUploads, ...newCsvUploads]
        });
    };

    addUpload(newUploads, file) {
        let fileNameWithoutExtension = file.name.split(".")[0];
        let fileExtension = file.name.split(".").pop().toLowerCase();
        let tableName = fileNameWithoutExtension.toLowerCase().replace(" ", "_");
        newUploads.push({
            name: fileNameWithoutExtension,
            schemaName: "data",
            tableName: tableName,
            nameError: false,
            schemaNameError: false,
            tableNameError: false,
            generateCache: true,
            uploading: false,
            id: uuidv4(),
            file: file,
            progress: 0,
            status: "Queued",
            type: fileExtension,
            cancelTokenSource: null,
            datasetId: this.props.datasetId
        });
    }

    clearCsvUploads = () => {
        this.setState({ csvUploads: [] });
    };

    onQueueUpload = (upload) => {
        const CancelToken = axios.CancelToken;
        const source = CancelToken.source();
        let newUpload = {
            ...upload,
            cancelTokenSource: source
        };

        this.uploadQueue.push(newUpload);

        this.setState({
            uploads: this.state.uploads.map((item) => {
                if (item.id === upload.id) {
                    return {
                        ...item,
                        uploading: true,
                        cancelTokenSource: source
                    };
                }
                return item;
            })
        });
    };

    onQueueAllUploads = () => {
        let uploads = this.state.uploads;
        let uploadMap = {};
        const CancelToken = axios.CancelToken;

        for (let i = 0; i < uploads.length; i++) {
            let upload = uploads[i];

            if (!upload.cancelTokenSource && this.validateInfo(upload)) {
                const source = CancelToken.source();
                upload.uploading = true;
                upload.cancelTokenSource = source;
                uploadMap[upload.id] = upload;
                this.uploadQueue.push(upload);
            }
        }

        this.setState({
            uploads: this.state.uploads.map((item) => {
                if (uploadMap.hasOwnProperty(item.id)) {
                    return {
                        ...item,
                        uploading: true,
                        cancelTokenSource: uploadMap[item.id].cancelTokenSource
                    };
                }
                return item;
            })
        });
    };

    validateInfo(upload) {
        switch (upload.type) {
            case "geojson":
            case "csv":
            case "zip":
            case "gpkg":
                upload.nameError = upload.name === "";
                upload.schemaNameError = upload.schemaName === "";
                upload.tableNameError = !ValidationUtils.validatePostgresIdentifier(upload.tableName);

                return !upload.nameError && !upload.tableNameError && !upload.schemaNameError;
            case "mbtiles":
                upload.nameError = upload.name === "";
                return !upload.nameError;
        }
        return false;
    }

    onCancelUpload = (upload) => {
        if (upload.status === "Uploading") {
            upload.cancelTokenSource.cancel();
        } else {
            this.setState({
                uploads: this.state.uploads.filter((x) => x.id !== upload.id)
            });
        }
    };

    onUploadProgress = (progressEvent, id) => {
        let percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
        this.setState({
            uploads: this.state.uploads.map((item) => {
                if (item.id === id) {
                    return {
                        ...item,
                        progress: percentCompleted,
                        status: percentCompleted === 100 ? "Processing" : "Uploading"
                    };
                }
                return item;
            })
        });
    };

    onCancelPreUpload = (upload) => {
        this.setState({
            uploads: this.state.uploads.filter((x) => x.id !== upload.id)
        });
    };

    onUploadChanged = (upload) => {
        this.setState({
            uploads: this.state.uploads.map((item) => {
                if (item.id === upload.lonfieldid) {
                    return upload;
                }
                return item;
            })
        });
    };

    downloadDatasetTemplate = (format) => {
        this.props.downloadDatasetTemplate(this.props.datasetId, "template.zip", format, {});
    };

    onCsvDialogAddGeojson = (file, geojson) => {
        let fileNameWithoutExtension = file.name.split(".")[0];
        let fileExtension = file.name.split(".").pop().toLowerCase();
        let tableName = fileNameWithoutExtension.toLowerCase().replace(" ", "_");

        let newUpload = {
            name: fileNameWithoutExtension,
            schemaName: "data",
            tableName: tableName,
            nameError: false,
            schemaNameError: false,
            tableNameError: false,
            generateCache: true,
            uploading: false,
            id: uuidv4(),
            file: geojson,
            progress: 0,
            status: "Queued",
            type: "memoryGeojson",
            cancelTokenSource: null,
            datasetId: this.props.datasetId
        };
        this.setState({
            uploads: [...this.state.uploads, newUpload],
            csvUploads: this.state.csvUploads.filter((x) => x.name != file.name)
        });
    };

    onCloseModal = () => {
        if (this.uploading || this.uploadQueue.length > 0) {
            const toastrConfirmOptions = {
                onOk: () => this.props.closeUploader(),
                onCancel: () => console.log("CANCEL: clicked")
            };
            toastr.confirm("Are you sure you want to cancel uploads?", toastrConfirmOptions);
        } else {
            this.props.closeUploader();
        }
    };

    render() {
        let { classes } = this.props;

        let uploads = this.state.uploads.map((upload) => {
            switch (upload.type) {
                case "geojson":
                case "csv":
                case "gpkg":
                case "zip":
                case "memoryGeojson":
                    return (
                        <Upload
                            uploadStatus={upload}
                            schemaNames={this.state.schemaNames}
                            onCancel={() => this.onCancelPreUpload(upload)}
                            onCancelUpload={this.onCancelUpload}
                            onChange={this.onUploadChanged}
                            onUpload={this.onQueueUpload}
                        />
                    );
                case "mbtiles":
                    return (
                        <RasterUpload
                            uploadStatus={upload}
                            onCancel={() => this.onCancelPreUpload(upload)}
                            onCancelUpload={this.onCancelUpload}
                            onChange={this.onUploadChanged}
                            onUpload={this.onQueueUpload}
                        />
                    );
            }
        });

        return (
            <div className="uploader">
                <div className="sub-header">
                    <Typography variant="h6" className="title">
                        Upload
                    </Typography>

                    <Button
                        variant="contained"
                        color="primary"
                        className="action-button"
                        onClick={() => {
                            document.getElementById("file-button").click();
                        }}
                    >
                        <AddIcon style={{ marginRight: 8, marginLeft: -8 }} />
                        Add Files
                    </Button>
                    <input
                        accept={this.state.accept}
                        className={classes.input}
                        style={{ display: "none" }}
                        multiple={true}
                        id="file-button"
                        type="file"
                        onChange={this.onFileChanged}
                    />
                    <Button variant="contained" color="primary" className="action-button" onClick={this.onOpenTemplateMenu}>
                        <CloudDownloadIcon style={{ marginRight: 8, marginLeft: -8 }} />
                        Download template
                    </Button>
                    <Menu id="simple-menu" anchorEl={this.state.templateMenuAnchor} keepMounted open={Boolean(this.state.templateMenuAnchor)} onClose={this.onCloseTemplateMenu}>
                        <MenuItem onClick={() => this.downloadDatasetTemplate("ESRI Shapefile")}>Download as Shapefile</MenuItem>
                        {this.props.geometryType.includes("POINT") && <MenuItem onClick={() => this.downloadDatasetTemplate("CSV")}>Download as Csv</MenuItem>}
                    </Menu>
                </div>
                <div
                    className={this.state.dragging ? "container dragging" : "container"}
                    onDrop={this.onFileDrop}
                    onDragLeave={this.onFileDragLeave}
                    onDragOver={this.onFileDragOver}
                >
                    {/* <div className="actions">
            <div className="flex-grow"></div>
            <Tooltip title="Upload All">
              <Button
                disabled={uploads.length === 0}
                variant="outlined"
                color="primary"
                onClick={this.onQueueAllUploads}
              >
                <CloudUploadIcon />
              </Button>
            </Tooltip>
          </div> */}
                    <div className="upload-list">
                        {uploads.length === 0 && <div className="no-uploads">Add a file to begin</div>}
                        {uploads}
                    </div>
                    {this.state.dragging && (
                        <div className="drag-overlay">
                            <Typography variant="h1">Drop Files</Typography>
                        </div>
                    )}
                </div>
                <CsvDialog
                    open={this.state.csvUploads.length > 0}
                    handleClose={this.clearCsvUploads}
                    file={this.state.csvUploads[0]}
                    onAddGeojson={this.onCsvDialogAddGeojson}
                ></CsvDialog>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        open: state.uploader.open,
        fetching: state.dataset
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        createDataset: (formData, config) => dispatch(datasetsActions.createDataset(formData, config)),
        createDatasetKml: (formData, config) => dispatch(datasetsActions.createDatasetKml(formData, config)),
        createDatasetCsv: (formData, config) => dispatch(datasetsActions.createDatasetCsv(formData, config)),
        appendDatasetZip: (datasetId, formData, config) => dispatch(datasetsActions.appendDatasetZip(datasetId, formData, config)),
        appendDatasetGeojson: (datasetId, formData, config) => dispatch(datasetsActions.appendDatasetGeojson(datasetId, formData, config)),
        createDatasetGeoPackage: (formData, config) => dispatch(datasetsActions.createDatasetGeoPackage(formData, config)),
        createRasterMbtiles: (formData, config) => dispatch(rasterServiceActions.createRaster(formData, config)),
        getSchemaNames: () => dispatch(datasetsActions.getSchemaNames()),
        getDataset: (datasetId) => dispatch(datasetsActions.getDatasetDetails(datasetId)),
        downloadDatasetTemplate: (datasetId, filename, format, config) => dispatch(datasetsActions.downloadDatasetTemplate(datasetId, filename, format, config))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Uploader));
