import makeStyles from "@mui/styles/makeStyles";

const headerSectionHeight = 40;

export const useHistoryDetailsModalStyles = makeStyles((theme) => ({
    dialogHeight: {
        height: 600
    },
    tableWrapper: {
        height: `calc(100% - ${headerSectionHeight}px);`
    },
    sectionTitle: {
        fontSize: 20,
        marginBottom: 10
    },
    centerContent: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100%"
    }
}));
