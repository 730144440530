import { Typography } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import React, { useEffect, useRef, useState } from "react";

const FeaturesTable = ({ features }) => {
    const sampleFeature = features[0];
    const columns = Object.keys(sampleFeature.properties).map((propName) => ({
        field: propName,
        header: (
            <Typography textTransform="capitalize" fontWeight="bold" fontSize={15}>
                {propName}
            </Typography>
        ),
        minWidth: 150,
        flex: 1
    }));

    const rows = features.map((feat) => feat.properties);
    return <DataGrid columns={columns} rows={rows} getRowId={(row) => row.ogc_fid} disableSelectionOnClick />;
};

export default FeaturesTable;
