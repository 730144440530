import React, { useState } from "react";
import withStyles from "@mui/styles/withStyles";
import {
    Typography,
    TextField,
    FormControlLabel,
    FormHelperText,
    Checkbox,
    Button,
    Select,
    MenuItem,
    FormControl,
    InputLabel,
    LinearProgress,
    Tooltip,
    Switch
} from "@mui/material";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import StopIcon from "@mui/icons-material/Close";
import DeleteIcon from "@mui/icons-material/Delete";
import CancelIcon from "@mui/icons-material/Cancel";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";

import * as ValidationUtils from "../../../utils/validationUtils";
import ReadOnlyField from "../../common/ReadOnlyField/ReadOnlyField";
import { Box } from "@mui/system";

const styles = (theme) => ({
    warningProgress: {
        backgroundColor: theme.palette.warning.main
    },
    errorProgress: {
        backgroundColor: theme.palette.error.main
    }
});

function Upload({ schemaNames, additionalInfo, onChange, onCancel, onCancelUpload, onUpload, uploadStatus, classes }) {
    function onNameChanged(e) {
        let value = e.target.value;
        let upload = uploadStatus;

        upload.name = value;
        upload.nameError = value === "";
        onChange(upload);
        //setNameError(value !== "");
    }

    function onCancelClicked(e) {
        onCancel();
    }

    function onUploadClicked(e) {
        if (validateInfo()) {
            onUpload(uploadStatus);
        }
    }

    function validateInfo() {
        let upload = uploadStatus;

        onChange(upload);

        return !upload.nameError && !upload.tableNameError && !upload.schemaNameError;
    }

    function renderProgressBar(upload, classes) {
        switch (upload.status) {
            case "Failed":
                return (
                    <div className="progress error">
                        <LinearProgress className="progress-bar" classes={{ bar: classes.errorProgress }} variant="determinate" value={upload.progress} />
                        <Tooltip title={uploadStatus.message}>
                            <div className="status">Failed</div>
                        </Tooltip>
                    </div>
                );
            case "Cancelled":
                return (
                    <div className="progress warning">
                        <LinearProgress className="progress-bar" classes={{ bar: classes.warningProgress }} variant="determinate" value={upload.progress} />
                        <div className="status">Cancelled</div>
                    </div>
                );
            default:
                return (
                    <div className="progress">
                        <LinearProgress className="progress-bar" variant="determinate" value={upload.progress} />
                        {upload.progress < 100 && upload.progress > 0 && <div className="status">{upload.progress}%</div>}
                        {(upload.progress === 100 || upload.progress === 0) && <div className="status">{upload.status}</div>}
                    </div>
                );
        }
    }

    return (
        <div className="upload">
            {!uploadStatus.uploading && (
                <div className="upload-container">
                    <div className="section grow">
                        <Box padding={1}>
                            <ReadOnlyField
                                id="full-width"
                                inputProps={{ autoComplete: "no" }}
                                error={uploadStatus.nameError}
                                placeholder="Name of the map"
                                text={uploadStatus.name}
                                onChange={onNameChanged}
                                fullWidth
                                margin="none"
                                variant="outlined"
                                label="File Name"
                                InputProps={{
                                    readOnly: true
                                }}
                            />
                        </Box>
                    </div>
                    <Tooltip title="Upload">
                        <div className="file-upload-button" onClick={onUploadClicked}>
                            <CloudUploadIcon />
                        </div>
                    </Tooltip>
                    <Tooltip title="Remove">
                        <div className="file-upload-button" onClick={onCancelClicked}>
                            <DeleteIcon />
                        </div>
                    </Tooltip>
                </div>
            )}
            {uploadStatus.uploading && (
                <div className="uploading-container">
                    <div className="uploading-info">
                        <Tooltip title={uploadStatus.name}>
                            <div className={"file-name " + uploadStatus.status}>{uploadStatus.name}</div>
                        </Tooltip>

                        {renderProgressBar(uploadStatus, classes)}

                        <Tooltip title={uploadStatus.status !== "Uploading" ? "Remove" : "Cancel"}>
                            <div className={"file-upload-button " + uploadStatus.status.toLowerCase()} onClick={() => onCancelUpload(uploadStatus)}>
                                {uploadStatus.status !== "Uploading" ? <DeleteIcon /> : <CancelIcon />}
                            </div>
                        </Tooltip>
                    </div>
                </div>
            )}
        </div>
    );
}

export default withStyles(styles)(Upload);
