import React, { Component } from "react";
import { connect } from "react-redux";
import { history } from "../store";

import withStyles from "@mui/styles/withStyles";

import { AppBar, LinearProgress, Toolbar, Typography } from "@mui/material";
import * as datasetActions from "../actions/datasets";
import ScopeItem from "../components/scopeItem";
import HeaderButtons from "../components/common/HeaderButtons/HeaderButtons";

const styles = (theme) => ({});

class ScopesView extends Component {
    constructor(props) {
        super(props);

        this.state = {};
    }

    componentDidMount() {}

    onScopeClick = (dataset) => {
        history.push("/upload/" + dataset.id);
    };

    render() {
        const scopes = this.props.datasets.map((dataset) => {
            return <ScopeItem key={dataset.id} dataset={dataset} selected={dataset.id === this.state.selected} onClick={this.onScopeClick} columns={this.state.columns} />;
        });

        return (
            <div className="scope-view-root">
                <AppBar position="static">
                    <Toolbar variant="dense" className="toolbar">
                        <img className="logo" src="/fidu-logo.png" alt="Lautec WindGIS"></img>
                        <div className="seperater"></div>
                        <HeaderButtons />
                    </Toolbar>
                </AppBar>
                <div className="header">
                    <Typography variant="h6" className="grow">
                        FiDU Scopes
                    </Typography>
                </div>
                {this.props.fetching && <LinearProgress variant="indeterminate" />}
                <div className="list-header">
                    <div className="type">Type</div>
                    <div className="grow">Name</div>
                    <div className="date">Last updated</div>
                </div>
                <div className="scopes-container">{scopes}</div>
            </div>
        );
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        datasets: state.datasets.datasets,
        fetching: state.datasets.fetching
    };
};

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        getDatasetsWithWritePermission: () => dispatch(datasetActions.getDatasetsWithWritePermission()),
        getDatasetColumns: (datasetId) => dispatch(datasetActions.getDatasetColumns(datasetId))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(ScopesView));
