import * as types from "../actions/actionTypes";

const uploaderInit = {};

export function uploaderReducer(state = uploaderInit, action) {
  switch (action.type) {
    default:
      return state;
  }
}
