import { useAuth0 } from "@auth0/auth0-react";
import React from "react";
import LoaderComponent from "./components/auth0Loader/LoaderComponent";

const Auth0Wrapper = ({ children }) => {
    const { isLoading, error } = useAuth0();
    if (isLoading) {
        return <LoaderComponent />;
    }
    if (error) {
        return <div>Oops... {error.message}</div>;
    }

    return <>{children}</>;
};

export default Auth0Wrapper;
